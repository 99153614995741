import { Row, H5 } from '@web/atomic';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { PartnersLogoComponentStyle, getPartnersLogoComponentH5Color, PartnersLogoComponentKind } from './PartnersLogoComponentStyle';

interface PartnersLogoComponentProps {
  imgs: IGatsbyImageData;
  kind: PartnersLogoComponentKind;
}

export const PartnersLogoComponent: React.FunctionComponent<PartnersLogoComponentProps> = (props) => {
  return (
    <PartnersLogoComponentStyle>
      <H5 color={getPartnersLogoComponentH5Color(props.kind)}>Uma startup acelerada por:</H5>
      <Row mt={true}>
        <GatsbyImage image={getImage(props.imgs)} alt="logo" imgStyle={{ objectFit: 'contain' }} />
      </Row>
    </PartnersLogoComponentStyle>
  );
};
